import HttpProxy from '@/services/HttpProxy';

export default class BusinessTaxService {
  static async create(data) {
    const response = await HttpProxy.submit({
      method: 'post',
      url: 'BusinessTax',
      data,
    });
    return response?.data ?? null;
  }

  static async update(data) {
    const response = await HttpProxy.submit({
      method: 'put',
      url: `BusinessTax/${data.id}`,
      data,
    });
    return response?.data ?? null;
  }

  static async delete(id) {
    const response = await HttpProxy.submit({
      method: 'delete',
      url: `BusinessTax/${id}`,
      parameters: {},
    });
    return response?.data ?? null;
  }
}
